<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button style="margin-bottom:20px" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'MerchantAdd' })">添加</el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="40%" :before-close="dialogBeforeClose" :show-close="false">
      <el-form ref="form" :model="formPwd" label-width="80px">
        <el-form-item label="新密码">
          <el-input style="width:300px;" v-model="model" size="small" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="save">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
const columns = [
  {
    label: '商户名',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '负责人',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '分配比例',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '状态',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <a-switch onChange={() => this.changeStatus(row)} checked-children="启用" un-checked-children="禁用" default-checked />
        </div>
      )
    }
  },

  {
    label: '创建时间',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link underline={false} type="primary" onClick={() => this.$router.push({ name: 'MerchantEdit', params: { id: row['id'] } })}>
            编辑
          </el-link>
          <el-link style="margin-left:10px;" underline={false} type="primary" onClick={() => this.handleDel(row)}>
            删除
          </el-link>
          <el-link style="margin-left:10px;" underline={false} type="primary" onClick={() => this.updatePwd(row)}>
            修改密码
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [{ id: 111 }],
      formPwd: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    // 删除
    handleDel() {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {},
    // 修改密码
    updatePwd() {
      this.dialogVisible = true
    },
    save() {
      this.dialogVisible = false
    },
    cancel() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
